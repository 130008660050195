import React from 'react';
import Layout from '../components/layout';

import Midsection from '../components/midsection';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title='PhoneFix PRO' />
    <Midsection />
    <div className='ls-background-light'>
      <section className='section'>
        <div className='container'>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
