import React from "react"
import {
  FaMobile,
  FaVolumeMute,
  FaShower,
  FaHeartBroken,
  FaSignal,
  FaBatteryEmpty,
} from "react-icons/fa"

import "./style.scss"

const Midsection = () => (
  <div>
    <section className="section">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-one-third">
            <article className="media">
              <figure className="media-left">
                <span className="icon is-medium">
                  <FaMobile style={{ fontSize: "32px" }} color="#d22780" />
                </span>
              </figure>
              <div className="media-content">
                <div className="content">
                  <h2 className="title is-size-4">Cracked Screen Repair</h2>
                  <p className="subtitle is-size-5">
                    Cracked screen replacement services are both fast and
                    affordable. In fact, a majority of iPhone screen repairs and
                    Android screen replacement can be completed within 30-45min,
                    if not while you wait in-store.
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div className="column is-one-third">
            <article className="media">
              <figure className="media-left">
                <span className="icon is-medium">
                  <FaVolumeMute
                    style={{ fontSize: "32px" }}
                    className="has-text-danger"
                  />
                </span>
              </figure>
              <div className="media-content">
                <div className="content">
                  <h2 className="title is-size-4">
                    Speaker / Earpiece Not Working
                  </h2>
                  <p className="subtitle is-size-5">
                    iPhone Speaker Repair, can't hear the person on the other
                    side. No audio through speaker or earpiece not working. We
                    provide iPhone speaker repair or Android Sound repair stop
                    by our Portland, OR location.
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div className="column is-one-third">
            <article className="media">
              <figure className="media-left">
                <span className="icon is-medium">
                  <FaShower style={{ fontSize: "32px" }} color="blue" />
                </span>
              </figure>
              <div className="media-content">
                <div className="content">
                  <h2 className="title is-size-4">Water Damage Repair</h2>
                  <p className="subtitle is-size-5">
                    Got your iPhone wet or dropped Android into the water we can
                    help. We can diagnose and will do our best to try to recover
                    Apple or Android device from water damage. 
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div className="column is-one-third">
            <article className="media">
              <figure className="media-left">
                <span className="icon is-medium">
                  <FaHeartBroken style={{ fontSize: "32px" }} color="blue" />
                </span>
              </figure>
              <div className="media-content">
                <div className="content">
                  <h2 className="title is-size-4">
                    Broken Buttons Replacement
                  </h2>
                  <p className="subtitle is-size-5">
                    Missing or broken buttons on your iPhone or Android, our
                    professional repair techs can help. A quick repair by
                    PhoneFix Pro will get on your way with a fixed Apple or
                    Android device and working buttons.
                  </p>
                </div>
              </div>
            </article>
          </div>

          <div className="column is-one-third">
            <article className="media">
              <figure className="media-left">
                <span className="icon is-medium">
                  <FaBatteryEmpty
                    style={{ fontSize: "32px" }}
                    className="has-text-primary"
                  />
                </span>
              </figure>
              <div className="media-content">
                <div className="content">
                  <h2 className="title is-size-4">Battery Replacement</h2>
                  <p className="subtitle is-size-5">
                    iPhone battery doesn't hold a charge anymore? PhoneFix Pro
                    Cell phone repair performs professional iPhone battery
                    replacements and Android battery replacement on tablets and
                    phones. Your Device can have a chance to live. 
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div className="column is-one-third">
            <article className="media">
              <figure className="media-left">
                <span className="icon is-medium">
                  <FaSignal
                    style={{ fontSize: "32px" }}
                    className="has-text-danger"
                  />
                </span>
              </figure>
              <div className="media-content">
                <div className="content">
                  <h2 className="title is-size-4">No Signal</h2>
                  <p className="subtitle is-size-5">
                    Weak signal? No Signal on your iPhone or Android device?
                    PhoneFix Pro is here to help, we'll diagnose your broken
                    iPhone or Android Device and we'll let you know if could be
                    repaired by our techs.
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default Midsection
